<template>
  <div class="content">
    <div class="page_title">
      <img src="../assets/images/detail/left_back.png" alt @click="goBack">
      <span>工单详情</span>
    </div>

    <div class="topCom f24" :class="{'isClosed': detailObj.work_order_status === 9}">
      <div>
        <img v-if="detailObj.work_order_status === 9" src="../assets/images/detail/car-closed-icon.png" alt>
        <img v-else src="../assets/images/detail/car-icon.png" alt>
        <div class="hGroup">
          <p>诊断工单</p>
          <p class="f20">{{ detailObj.created_at }}</p>
        </div>
      </div>
      <div>{{ workStatusObj[detailObj.work_order_status] }}</div>
    </div>

    <!-- 基本信息 -->
    <section>
      <h3>基本信息</h3>
      <h4>车辆信息</h4>
      <div class="row">
        <label>车牌号</label>
        <span>{{ detailObj.license_plate }}</span>
      </div>
      <div class="row">
        <label>VIN</label>
        <span>{{ detailObj.vin }}</span>
      </div>
      <div class="row">
        <label>车辆型号</label>
        <span>{{ detailObj.vehicle_type }}</span>
      </div>
      <div class="row">
        <label>行驶里程</label>
        <span>{{ detailObj.driving_miles }}</span>
      </div>
      <div class="row">
        <label>质保期</label>
        <span>{{ detailObj.warranty }}</span>
      </div>
    </section>

    <!-- 故障现象 -->
    <section class="fault">
      <h3>故障现象</h3>
      <h4>
        <img src="../assets/images/detail/fault_icon_01.png" alt="">
        故障描述
      </h4>
      <div class="row">
        <p>{{ detailObj.fault_description }}</p>
      </div>
      <h4>
        <img src="../assets/images/detail/fault_icon_02.png" alt="">
        故障发生时车辆状态
      </h4>
      <div class="row">
        <div class="row">
          <label>发动机状态</label>
          <span>{{ detailObj.engine_status }}</span>
        </div>
        <div class="row">
          <label>转向情况</label>
          <span>{{ detailObj.steering_situation }}</span>
        </div>
      </div>
      <div class="row">
        <div class="row">
          <label>发动机工况</label>
          <span>{{ detailObj.engine_condition }}</span>
        </div>
        <div class="row">
          <label>制动情况</label>
          <span>{{ detailObj.braking_use }}</span>
        </div>
      </div>
      <div class="row">
        <div class="row">
          <label>档位</label>
          <span>{{ detailObj.gear_position }}</span>
        </div>
        <div class="row">
          <label>用电设备</label>
          <span>{{ detailObj.power_consumption_level }}</span>
        </div>
      </div>
      <div class="row">
        <div class="row">
          <label>行驶速度</label>
          <span>{{ detailObj.running_speed }}</span>
        </div>
        <div class="row">
          <label>故障灯 </label>
          <span>{{ detailObj.fault_lamp }}</span>
        </div>
      </div>

      <h4>
        <img src="../assets/images/detail/fault_icon_03.png" alt="">
        车辆经常使用环境
      </h4>

      <div class="row">
        <div class="row">
          <label>常用地区</label>
          <span>{{ detailObj.common_areas }}</span>
        </div>
        <div class="row">
          <label>保养情况</label>
          <span>{{ detailObj.maintenance_condition }}</span>
        </div>
      </div>

      <div class="row">
        <div class="row">
          <label>常用路况</label>
          <span>{{ detailObj.common_road_conditions }}</span>
        </div>
        <div class="row">
          <label>故障规律</label>
          <span>{{ detailObj.failure_rule }}</span>
        </div>
      </div>
      <div class="row">
        <label>其他说明</label>
        <span>{{ detailObj.remarks }}</span>
      </div>
      <div class="row">
        <label>接待结论</label>
        <span>{{ detailObj.reception_conclusion }}</span>
      </div>
      <h4>
        <img src="../assets/images/detail/fault_icon_04.png" alt="">
        备注信息
      </h4>
      <div class="row">
        <p>{{ detailObj.remarks_information }}</p>
      </div>
    </section>

    <!-- 诊断信息 -->
    <template v-for="(item, index) in diagnoseList">
      <section :key="index" class="diagnose">
        <h3>诊断信息{{ Number(index) + 1 }}</h3>
        <h4>诊断过程</h4>
        <div class="row">
          <label>系统</label>
          <span>{{ item.system_name }}</span>
        </div>
        <div class="row">
          <label>部位</label>
          <span>{{ item.part_name }}</span>
        </div>
        <div class="row">
          <label>故障内容</label>
          <span>{{ item.fault_name }}</span>
        </div>
        <div class="row">
          <label>故障代码</label>
          <span>{{ item.fault_code }}</span>
        </div>
        <div class="row">
          <label>测量数据</label>
          <span class="longSpan">
            <div v-for="(metrical, metricalIndex) in item.metrical_data" :key="metricalIndex">
              <i>{{ metrical.name }}</i> <i>{{ metrical.value }}</i> <i>{{ metrical.unit }}</i>
            </div>
          </span>
        </div>
        <div class="row">
          <label>故障原因</label>
          <span>{{ item.fault_reason }}</span>
        </div>

        <div class="row">
          <label>图片上传</label>
          <ul class="photoList">
            <li
              v-for="(photo, photoIndex) in item.photo_file_list"
              :key="photoIndex"
              @click="imagePreview(item.photo_file_list, photoIndex)"
            >
              <img :src="photo.file_url" alt="">
            </li>
          </ul>
        </div>

        <div v-if="item.video_file_list.length > 0" class="row">
          <label>视频上传</label>
          <ul class="videoList">
<!--            <video class="video-source"-->
<!--            style="object-fit:fill"  /*加这个style会让 Android / web 的视频在微信里的视频全屏，如果是在手机上预览，会让视频的封面同视频一样大小*/-->
<!--            webkit-playsinline="true"  /*这个属性是ios 10中设置可以让视频在小窗内播放，也就是不是全屏播放*/-->
<!--            x-webkit-airplay="true"  /*这个属性还不知道作用*/-->
<!--            playsinline="true"  /*IOS微信浏览器支持小窗内播放*/-->
<!--            x5-video-player-type="h5" /*启用H5播放器,是wechat安卓版特性*/-->
<!--            x5-video-orientation="h5" /*播放器支付的方向，landscape横屏，portraint竖屏，默认值为竖屏*/-->
<!--            x5-video-player-fullscreen="true" /*全屏设置，设置为 true 是防止横屏*/-->
<!--            preload="auto" /*这个属性规定页面加载完成后载入视频*/-->
<!--            </video>-->
            <li v-for="(video, videoIndex) in item.video_file_list" :key="videoIndex">
              <video
                :src="video.file_url"
                controls
                playsinline="false"
                webkit-playsinline="false"
                x5-playsinline="false"
                x5-video-player-type="h5"
                x5-video-player-fullscreen="true"
                x-webkit-airplay="true"
                preload="auto"
                style="object-fit:fill"
              />
            </li>
          </ul>
        </div>

        <h4>诊断结论</h4>
        <template v-for="(result, resultIndex) in item.result">
          <div :key="resultIndex" class="resultList">
            <div class="row">
              <label>排除方法</label>
              <span>{{ result.solutionText + ' ' +result.description }}</span>
            </div>
            <div class="row">
              <div class="row">
                <label>故障部位</label>
                <span>{{ result.fault_part_dict_name }}</span>
              </div>
              <div class="row">
                <label>零部件编号</label>
                <span>{{ result.use_part_no }}</span>
              </div>
            </div>
            <div class="row">
              <label>零部件</label>
              <span>{{ result.use_part_name }}</span>
            </div>

          </div>
        </template>

      </section>
    </template>

    <!-- 维修建议 -->
    <section v-if="detailObj.work_order_status > 2">
      <h3>维修建议</h3>
      <div class="row">
        <label>重大项目</label>
        <span>{{ detailObj.major_projects }}</span>
      </div>
      <div class="row">
        <label>一般项目</label>
        <span>{{ detailObj.general_items }}</span>
      </div>
      <div class="row">
        <label>潜在项目</label>
        <span>{{ detailObj.potential_projects }}</span>
      </div>
    </section>

    <!-- 其他信息 -->
    <section class="otherInfo">
      <h3>其他信息</h3>
      <div class="row">
        <label>服务顾问</label>
        <span>{{ detailObj.service_consultant }}</span>
      </div>
      <div class="row">
        <label>工单号</label>
        <span>{{ detailObj.work_order_no }}</span>
      </div>
      <div class="row">
        <label>分配时间</label>
        <span>{{ detailObj.distribution_time }}</span>
      </div>
      <div v-if="detailObj.finish_time" class="row">
        <label>完成时间</label>
        <span>{{ detailObj.finish_time }}</span>
      </div>
    </section>

    <!--待诊断-->
    <footer-btn
      v-if="detailObj.work_order_status == 2"
      :btn-num="2"
      cancel-text="转给同事"
      confirm-text="填写诊断信息"
      @cancel="give"
      @confirm="toDiagnose('200004')"
    />

    <!--待沟通-->
    <footer-btn
      v-if="detailObj.work_order_status == 3"
      confirm-text="完善诊断信息"
      @confirm="toDiagnose"
    />

    <!--待检验-->
    <footer-btn
      v-if="detailObj.work_order_status == 4"
      :btn-num="2"
      cancel-text="检验完毕"
      confirm-text="完善诊断信息"
      @cancel="isShowCheckPopup = true"
      @confirm="toDiagnose"
    />

    <!--已完成、未留修 完成时间24小时以内-->
    <footer-btn
      v-if="!isOverTime && (detailObj.work_order_status == 5 || detailObj.work_order_status == 6)"
      confirm-text="完善诊断信息"
      @confirm="toDiagnose"
    />

    <confirm-modal
      :show-popup="isShowCheckPopup"
      title="确认已完成检验？"
      icon="is_check_icon"
      @closePopup="isShowCheckPopup = false"
      @confirm="confirmCheck"
    />

    <!--    <div v-if="detailObj.status==2" class="btn_back" />-->

    <!--    &lt;!&ndash; 待诊断 &ndash;&gt;-->
    <!--    <div v-if="detailObj.work_order_status==2" class="bottom">-->
    <!--      <span @click="give">转给同事</span>-->
    <!--      <span @click="toDiagnose">填写诊断信息</span>-->
    <!--    </div>-->
    <!--    &lt;!&ndash; 待沟通 &ndash;&gt;-->
    <!--    <div v-else-if="detailObj.work_order_status==3" class="bottom">-->
    <!--      <span class="complete" @click="toDiagnose">完善诊断信息</span>-->
    <!--    </div>-->
    <!--    &lt;!&ndash; 待检验 &ndash;&gt;-->
    <!--    <div v-else-if="detailObj.work_order_status==4" class="bottom">-->
    <!--      <span @click="toCheckout">检验完毕</span>-->
    <!--      <span @click="toDiagnose">完善诊断信息</span>-->
    <!--    </div>-->
    <!--    &lt;!&ndash; 已完成 &ndash;&gt;-->
    <!--    <div v-else-if="detailObj.work_order_status==5&&detailObj.status==2||detailObj.work_order_status==6&&detailObj.status==2" class="bottom">-->
    <!--      <span class="complete" @click="toDiagnose">完善诊断信息</span>-->
    <!--    </div>-->

    <!-- 转给同事弹窗 -->
    <BtmModal
      v-if="flag"
      :text="title_text"
      :value="modalShow"
      :data="list"
      @on-click="giveColleague"
      @on-cancel="giveCancel"
    />
    <GoHome />
  </div>
</template>

<script>
import port from '@/api/port'
import cookies from '@/utils/cookie.js'
import FooterBtn from '@/components/footerBtn'
import ConfirmModal from '@/base/modal/confirmModal'

export default {
  name: 'Detail',
  components: { ConfirmModal, FooterBtn },
  data() {
    return {
      workStatusObj: {
        2: '待诊断',
        3: '待沟通',
        4: '待检验',
        5: '已完成',
        6: '未留修',
        9: '已关闭'
      },
      lineShow: {
        display: 'block'
      },
      lineNone: {
        display: 'none'
      },
      flag: false,
      detailObj: {
        status: 4
      },
      diagnoseList: [],
      isShowCheckPopup: false,
      modalShow: false, // 转给同事弹窗
      changeShowImgs: false, // 图片轮播背景
      list: [],
      changeShow: false, // 确认已完成检验弹窗
      title_text: '请选择要转交的同事'
    }
  },
  computed: {
    id() {
      return this.$route.query.id
    },
    isOverTime() {
      if (this.detailObj.finish_time) {
        return new Date().getTime() - new Date(this.detailObj.finish_time.replace(/\-/g, '/')).getTime() > 3600 * 24 * 1000
      } else {
        return true
      }
    }
  },
  created() {
    port.getWorkDetail({
      work_id: this.id
    }).then(res => {
      this.detailObj = res.data
    })

    port.getDiagnoseList({
      work_id: this.id
    }).then(res => {
      this.diagnoseList = res.data
    })
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    // 转给同事-展示弹窗
    give() {
      // 转给同事列表
      const params1 = {
        method: '200002',
        work_id: this.id,
        token: cookies.get('token')
      }
      port.portAddress(params1).then(res => {
        if (res.status == 200) {
          this.flag = true
          this.list = res.data
          this.modalShow = true
          document.getElementsByTagName('body')[0].style.overflowY = 'hidden'
        } else {
          this.$toast({ message: res.msg })
        }
      })
    },
    // 确认转同事
    giveColleague(val) {
      this.modalShow = false
      const params = {
        method: '200003',
        work_id: this.id,
        user_id: val.id,
        token: cookies.get('token')
      }
      port.portAddress(params).then(res => {
        if (res.status == 200) {
          this.$router.push({
            path: '/home',
            query: {
              msg: '转交成功'
            }
          })
        } else {
          this.$toast({ message: res.msg })
        }
      })
    },
    giveCancel() {
      this.modalShow = false
      document.getElementsByTagName('body')[0].style.overflowY = 'auto'
    },
    // 填写诊断信息
    toDiagnose(method = '200005') {
      const _this = this
      this.$router.push({
        path: '/diagnose',
        query: {
          id: _this.id,
          method
        }
      })
    },
    // 确定完成检验
    confirmCheck() {
      const params = {
        method: '200006',
        work_id: this.id,
        token: cookies.get('token')
      }
      port.portAddress(params).then(res => {
        if (res.status == 200) {
          this.isShowCheckPopup = false
          window.location.reload()
        } else {
          this.$toast({ message: res.msg })
        }
      })
    },
    // 预览图片
    imagePreview(photo_file_list, photoIndex) {
      this.$imagePreview({
        images: photo_file_list.map(item => {
          return item.file_url
        }),
        startPosition: photoIndex
      })
    }
  }
}
</script>

<style scoped lang="less">

.content {
  padding: 0.75rem 0 .84rem;
  .topCom{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    padding: .17rem .27rem;
    background: linear-gradient(90deg, #2F8AF1 0%, #8BDDF4 100%);
    &.isClosed{
      background: #D5D5D5;
    }
    img{
      width: .68rem;
      height: .68rem;
      float: left;
      margin-right: .14rem;
    }
    .hGroup{
      height: .68rem;
      width: 3rem;
      line-height: .34rem;
    }
    span{
    }
  }

 section{
   padding: .41rem .27rem;
   font-size: .2rem;
   background: #fff;
   margin-bottom: .14rem;
   h3{
     line-height: .38rem;
     font-size: .27rem;
     font-weight: 400;
     margin-bottom: .41rem;
     padding-left: .17rem;
     position: relative;
     &:after{
       content: '';
       position: absolute;
       left: 0;
       top: .05rem;
       width: .07rem;
       height: .27rem;
       background: #2F8AF1;
     }
   }
   h4{
     font-size: .24rem;
     font-weight: bold;
     line-height: .34rem;
     margin-bottom: .27rem;
   }

   .row{
     display: flex;
     align-items: flex-start;
     line-height: .29rem;
     margin-bottom: .27rem;
     &:last-child{
       margin-bottom: 0;
     }
     label{
       color: #999;
       width: 1.23rem;
     }
     span{
       color: #333;
       max-width: 4.51rem;
       word-break: break-all;
     }
     ul{
       width: 4.51rem;
     }
     p{
       color: #666;
     }
   }
 }

  .fault{
    h4{
      display: flex;
      align-items: center;
      img{
        width: .24rem;
        height: .24rem;
        margin-right: .07rem;
      }
    }
    .row{
      flex: 1;
      .row{
        margin-bottom: 0;
      }
    }
  }

  .diagnose{
    .longSpan{
      width: 4.51rem;
      span{
        display: block;
      }
      i{
        font-style: normal;
        margin-right: .2rem;
      }
    }
    .photoList,
    .videoList{
      display: flex;
      flex-wrap: wrap;
      width: 4.79rem;
      li{
        width: 1.48rem;
        height: 1.48rem;
        margin: 0 .07rem .07rem 0;
      }
      img, video{
        width: 1.48rem;
        height: 1.48rem;
        background: #F3F3F3;
        border-radius: .1rem;
      }
      img{
        object-fit: cover;
      }
    }
    .row{
      flex: 1;
      .row{
        margin-bottom: 0;
      }
    }
    .resultList:not(:last-child){
      border-bottom: .02rem #F3F3F3 solid;
      padding-bottom: .24rem;
      margin-bottom: .24rem;
    }
  }

  .otherInfo{
    span{
      width: 4.51rem;
      text-align: right;
    }
  }

}
</style>
